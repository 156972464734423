import React from "react";
import { CardContext } from "../card/context";
import { Card } from "../card/card";
import Masonry from "react-masonry-css";

import * as styles from "../global/global.module.css";
import { cn } from "../../lib/helpers";

const STORY_CTA_TEXT = "Read the story";

export const ReferenceList = ({ section }) => {
  return (
    <Masonry
      breakpointCols={{
        default: 3,
        1020: 2,
        600: 1,
      }}
      className={styles.masonryGrid}
      columnClassName={styles.masonryGridColumn}
    >
      {section.list.map((doc, i) => (
        <CardContext.Provider
          key={i}
          value={{
            ...doc,
            title: false,
            quote: doc._type === "quotable" ? doc : doc.quote,
            hideImage: true,
            showSummary: true,
            showByline: false,
            maxColumns: 3,
            // showExtended: true,
            // showSubTags: true,
            hideLabels: true,
            hideType: true,
            hideDate: true,
            cta: doc._type === "quotable" ? null : STORY_CTA_TEXT,
            showLogo: true,
            isTall: true,
            isMasonry: true,
            skipLink: doc._type === "quotable",
          }}
        >
          <Card
            className={cn(
              "border border-dark-10",
              doc._type === "quotable"
                ? null
                : "hover:shadow-lg hover:border-dark-20"
            )}
          />
        </CardContext.Provider>
      ))}
    </Masonry>
  );
};
